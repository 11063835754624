<template>
  <div class="form-row form-search">
    <div class="col-lg-9">
      <v-select
        v-model="selectedPrefecture"
        :append-to-body="true"
        :options="prefectureOptions"
        :clearable="false"
      >
        <div slot="no-options">{{ $t('noMatch') }}</div>
      </v-select>
    </div>
    <div class="col-lg-3">
      <button
        type="button"
        class="h-100 form-search__search-btn"
        @click="searchWithPrefecture"
      >
        <img
          class="icon"
          v-lazy-load data-src="~assets/img/ic-search.svg"
          :alt="$t('search')"
        />{{ $t('search') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeAreaSelector',
  props: {
    prefectures: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      blankOption: {
        label: this.$t('area'),
        slug: null
      },

      selectedPrefecture: {
        label: this.$t('area'),
        slug: null
      }
    }
  },

  computed: {
    prefectureOptions() {
      const prefectures = this.prefectures.map((prefecture) => {
        return {
          label: prefecture.name,
          slug: prefecture.slug
        }
      })

      return [this.blankOption, ...prefectures]
    }
  },

  methods: {
    searchWithPrefecture() {
      if (!this.selectedPrefecture.slug) {
        this.$router.push(this.localePath('/list'))
      } else {
        this.$router.push(
          this.localePath(`/${this.selectedPrefecture.slug}/list`)
        )
      }
    }
  }
}
</script>
