<template>
  <div class="list-city">
    <div class="row">
      <div class="col-lg-4">
        <nuxt-link :to="localePath(`/tokyo`)" class="item item-bg1"
          >TOKYO
        </nuxt-link>
      </div>
      <div class="col-lg-4">
        <nuxt-link :to="localePath(`/osaka`)" class="item item-bg2"
          >OSAKA
        </nuxt-link>
      </div>
      <div class="col-lg-4">
        <nuxt-link :to="localePath(`/fukuoka`)" class="item item-bg3"
          >FUKUOKA
        </nuxt-link>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <nuxt-link :to="localePath(`/kanagawa`)" class="item item-bg4"
          >KANAGAWA
        </nuxt-link>
      </div>
      <div class="col-lg-4">
        <nuxt-link :to="localePath(`/saitama`)" class="item item-bg5"
          >SAITAMA
        </nuxt-link>
      </div>
      <div class="col-lg-4">
        <nuxt-link :to="localePath(`/hyogo`)" class="item item-bg6"
          >HYOGO
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePopularCities'
}
</script>
