export default {
  headMeta: undefined,
  head() {
    return {
      title: this.headMeta.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.headMeta.description
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: this.headMeta.type ? this.headMeta.type : 'article'
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.headMeta.title
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.headMeta.description
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.baseUrl + this.$route.path
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: this.$t('headMeta.title')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.headMeta.image
            ? this.headMeta.image
            : this.$config.baseUrl + '/images/header-background.png'
        }
      ]
    }
  }
}
