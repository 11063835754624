<template>
  <div class="intro-right">
    <div class="intro-title">{{ $t('search_by_theme') }}</div>
    <div class="intro-list">
      <nuxt-link
        v-for="theme in themes"
        :key="theme.id"
        :to="localePath(`/list?theme=${theme.id}`)"
        class="item"
      >
        <div class="item-inner">
          <div class="item__img">
            <img
              class="icon"
              v-lazy-load :data-src="theme.icon"
              :alt="$t(theme.i18n_key) + '|' + $t('headMeta.title')"
            />
          </div>
          <div class="item__txt">
            {{ $t(theme.i18n_key) }}
          </div>
        </div>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import {
  THEME_NO_DEPOSIT_KEY_MONEY,
  THEME_NO_GUARANTOR,
  THEME_HAS_FURNITURE,
  THEME_LIVING_ALONE,
  THEME_STUDENT,
  THEME_FAMILY_PROPERTY,
  THEME_NEWLYWED_COUPLE,
  THEME_CREDIT_CARD_PAYMENT,
  THEME_PET_ALLOWED
} from '@/constants/theme'

export default {
  name: 'HomeSpecialTheme',
  data() {
    return {
      themes: [
        {
          ...THEME_NO_DEPOSIT_KEY_MONEY,
          icon: require('../../assets/img/intro-ic1.svg')
        },
        {
          ...THEME_NO_GUARANTOR,
          icon: require('../../assets/img/intro-ic2.svg')
        },
        {
          ...THEME_HAS_FURNITURE,
          icon: require('../../assets/img/intro-ic3.svg')
        },
        {
          ...THEME_LIVING_ALONE,
          icon: require('../../assets/img/intro-ic4.svg')
        },
        {
          ...THEME_STUDENT,
          icon: require('../../assets/img/intro-ic5.svg')
        },
        {
          ...THEME_FAMILY_PROPERTY,
          icon: require('../../assets/img/intro-ic6.svg')
        },
        {
          ...THEME_NEWLYWED_COUPLE,
          icon: require('../../assets/img/intro-ic7.svg')
        },
        {
          ...THEME_CREDIT_CARD_PAYMENT,
          icon: require('../../assets/img/intro-ic8.svg')
        },
        {
          ...THEME_PET_ALLOWED,
          icon: require('../../assets/img/intro-ic9.svg')
        }
      ]
    }
  }
}
</script>
