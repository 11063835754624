var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section section-recommended"},[(_vm.isLoading)?_c('Loader'):_c('div',{staticClass:"container"},[(_vm.properties.length)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"section-title"},[_c('div',{staticClass:"headTitle"},[_vm._v(_vm._s(_vm.$t('recommended_rooms')))])])]),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"section-button text-right"},[_c('nuxt-link',{staticClass:"see-more",attrs:{"to":_vm.localePath({
                path: _vm.redirectPatch,
                query: _vm.seeMoreQuery
              })}},[_vm._v(_vm._s(_vm.$t('see_more'))+"\n          ")])],1)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-11"},[_c('div',{staticClass:"section-card"},[_c('div',{staticClass:"row section-card-inner no-gutter"},_vm._l((_vm.properties),function(property){return _c('div',{key:property.id,staticClass:"col-lg-3 d-flex flex-row"},[_c('nuxt-link',{staticClass:"item",attrs:{"to":_vm.localePath({
                    name: 'property-id',
                    params: {
                      id: property.id
                    }
                  })}},[_c('div',{staticClass:"item-top"},[(property.is_designed)?_c('span',{staticClass:"txt-badge"},[_vm._v(_vm._s(_vm.$t('designer')))]):(property.is_renovated)?_c('span',{staticClass:"txt-badge"},[_vm._v(_vm._s(_vm.$t('renovated')))]):_vm._e(),_vm._v(" "),(property.living_image)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"w-100",attrs:{"data-src":_vm.getThumbnailUrl(property.living_image.url, 200),"alt":[
                      _vm.$t('rental_apartment'),
                      property.building.master_prefecture.name,
                      property.building.nearest_transportation.line.name,
                      property.building.nearest_transportation.station.name
                    ]}}):(property.outdoor_image)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"w-100",attrs:{"data-src":_vm.getThumbnailUrl(property.outdoor_image.url, 200),"alt":[
                      _vm.$t('rental_apartment'),
                      property.building.master_prefecture.name,
                      property.building.nearest_transportation.line.name,
                      property.building.nearest_transportation.station.name
                    ]}}):_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"w-100",attrs:{"data-src":require("assets/img/no-image@2x.jpg"),"alt":[
                      _vm.$t('rental_apartment'),
                      property.building.master_prefecture.name,
                      property.building.nearest_transportation.line.name,
                      property.building.nearest_transportation.station.name
                    ]}})]),_vm._v(" "),_c('div',{staticClass:"item-body"},[(property.property_fee)?_c('div',{staticClass:"item-title-bold",domProps:{"innerHTML":_vm._s(
                      _vm.$t('price_yen_per_month', {
                        price: _vm.$options.filters.priceFormat(
                          property.property_fee.rent_price
                        )
                      })
                    )}}):_vm._e(),_vm._v(" "),(property.property_fee)?_c('div',{staticClass:"item-sub-title"},[_vm._v("\n                    "+_vm._s(_vm.$t('management_fee'))+"：￥"+_vm._s(_vm.$options.filters.priceFormat(
                        property.property_fee.management_fee
                      ))+"\n                  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"item-content"},[_c('span',[_vm._v(_vm._s(("" + (property.number_rooms) + (property.room_type_name)))+"\n                      /\n                      "+_vm._s(_vm.$t('number_floor', {
                          num: property.number_floors
                        }))+"\n                      / "+_vm._s(property.building_area)+"m²")]),_vm._v(" "),(property.building.nearest_transportation)?_c('span',[_vm._v(_vm._s(((property.building.nearest_transportation.line
                          ? property.building.nearest_transportation.line.name
                          : '') + "　" + (property.building.nearest_transportation.station
                          ? property.building.nearest_transportation.station
                              .name
                          : '') + "　" + (_vm.$t('minutes_on_foot', {
                        min:
                          property.building.nearest_transportation
                            .walking_time
                      })))))]):_vm._e()])])])],1)}),0)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }