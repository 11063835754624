<template>
  <section class="section section-search-link">
    <div class="container">
      <div class="row no-gutter">
        <div class="col-lg-12">
          <RecomendCriteriaPropertyType></RecomendCriteriaPropertyType>
        </div>
        <div class="col-lg-12">
          <RecomendCriteriaTheme></RecomendCriteriaTheme>
        </div>
        <div class="col-lg-12">
          <div class="list-search search-prefecture">
            <div class="row">
              <div class="col-lg-12">
                <div class="section-title">
                  <div class="headTitle mb-24">
                    {{ $t('search_by_prefecture') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="list-link">
                  <div>
                    <template v-for="(pref, index) in prefectures">
                      <nuxt-link
                        v-if="index < prefectures.length / 2"
                        :key="pref.id"
                        :to="localePath(`/${pref.slug}`)"
                        >{{ pref.name }}
                      </nuxt-link>
                    </template>
                  </div>
                  <div>
                    <template v-for="(pref, index) in prefectures">
                      <nuxt-link
                        v-if="index >= prefectures.length / 2"
                        :key="pref.id"
                        :to="localePath(`/${pref.slug}`)"
                        >{{ pref.name }}
                      </nuxt-link>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RecomendCriteriaTheme from '@/components/common/search/RecomendCriteriaTheme'
import RecomendCriteriaPropertyType from '@/components/common/search/RecomendCriteriaPropertyType.vue'

export default {
  name: 'QuickSearchLink',
  components: {
    RecomendCriteriaTheme,
    RecomendCriteriaPropertyType
  },
  props: {
    prefectures: { type: Array, default: () => [] }
  }
}
</script>
