<template>
  <section class="section section-city section-bg">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">
            <div class="headTitle">
              {{ $t('popular_cities_among_foreigners') }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <HomePopularCities></HomePopularCities>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import HomePopularCities from '@/components/home/HomePopularCities'

export default {
  name: 'PopularCities',
  components: { HomePopularCities }
}
</script>
