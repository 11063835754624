<template>
  <!-- eslint-disable vue/no-v-html -->
  <section class="section section-recommended">
    <Loader v-if="isLoading"></Loader>
    <div v-else class="container">
      <div v-if="properties.length" class="row">
        <div class="col-6">
          <div class="section-title">
            <div class="headTitle">{{ $t('recommended_rooms') }}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="section-button text-right">
            <nuxt-link
              class="see-more"
              :to="
                localePath({
                  path: redirectPatch,
                  query: seeMoreQuery
                })
              "
              >{{ $t('see_more') }}
            </nuxt-link>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-11">
          <div class="section-card">
            <div class="row section-card-inner no-gutter">
              <div
                v-for="property in properties"
                :key="property.id"
                class="col-lg-3 d-flex flex-row"
              >
                <nuxt-link
                  class="item"
                  :to="
                    localePath({
                      name: 'property-id',
                      params: {
                        id: property.id
                      }
                    })
                  "
                >
                  <div class="item-top">
                    <span v-if="property.is_designed" class="txt-badge">{{
                      $t('designer')
                    }}</span>
                    <span v-else-if="property.is_renovated" class="txt-badge">{{
                      $t('renovated')
                    }}</span>
                    <img
                      v-if="property.living_image"
                      class="w-100"
                      v-lazy-load :data-src="getThumbnailUrl(property.living_image.url, 200)"
                      :alt="[
                        $t('rental_apartment'),
                        property.building.master_prefecture.name,
                        property.building.nearest_transportation.line.name,
                        property.building.nearest_transportation.station.name
                      ]"
                    />
                    <img
                      v-else-if="property.outdoor_image"
                      class="w-100"
                      v-lazy-load :data-src="getThumbnailUrl(property.outdoor_image.url, 200)"
                      :alt="[
                        $t('rental_apartment'),
                        property.building.master_prefecture.name,
                        property.building.nearest_transportation.line.name,
                        property.building.nearest_transportation.station.name
                      ]"
                    />
                    <img
                      v-else
                      class="w-100"
                      v-lazy-load data-src="~assets/img/no-image@2x.jpg"
                      :alt="[
                        $t('rental_apartment'),
                        property.building.master_prefecture.name,
                        property.building.nearest_transportation.line.name,
                        property.building.nearest_transportation.station.name
                      ]"
                    />
                  </div>
                  <div class="item-body">
                    <div
                      v-if="property.property_fee"
                      class="item-title-bold"
                      v-html="
                        $t('price_yen_per_month', {
                          price: $options.filters.priceFormat(
                            property.property_fee.rent_price
                          )
                        })
                      "
                    ></div>
                    <div v-if="property.property_fee" class="item-sub-title">
                      {{ $t('management_fee') }}：￥{{
                        $options.filters.priceFormat(
                          property.property_fee.management_fee
                        )
                      }}
                    </div>
                    <div class="item-content">
                      <span
                        >{{
                          `${property.number_rooms}${property.room_type_name}`
                        }}
                        /
                        {{
                          $t('number_floor', {
                            num: property.number_floors
                          })
                        }}
                        / {{ property.building_area }}m²</span
                      >
                      <span v-if="property.building.nearest_transportation">{{
                        `${
                          property.building.nearest_transportation.line
                            ? property.building.nearest_transportation.line.name
                            : ''
                        }　${
                          property.building.nearest_transportation.station
                            ? property.building.nearest_transportation.station
                                .name
                            : ''
                        }　${$t('minutes_on_foot', {
                          min:
                            property.building.nearest_transportation
                              .walking_time
                        })}`
                      }}</span>
                    </div>
                  </div>
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loader from '@/components/common/Loader'
import { THEME_DESIGNERS } from '@/constants/theme'
import { getThumbnailUrl } from '~/utils/thumbnail'

const PROPERTY_LIMIT = 8
const FRIST_PAGE = 1

export default {
  name: 'HomeRecommendProperties',
  components: {
    Loader
  },
  props: {
    previousSearchCriteria: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      properties: [],
      isLoading: false,
      prefectureSlug: null,
      THEME_DESIGNERS,
      seeMoreQuery: {}
    }
  },
  computed: {
    redirectPatch() {
      return this.prefectureSlug ? `/${this.prefectureSlug}/list` : '/list'
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    getThumbnailUrl,
    async fetchData() {
      try {
        this.isLoading = true

        return this.previousSearchCriteria
          ? await this.getPropertyByPreviosCriteria()
          : await this.getPropertyByRecommend()
      } catch (error) {
        console.log('fetchData', error)
      } finally {
        this.isLoading = false
      }
    },
    async getPrefectureByID(id) {
      const prefecture = await this.$services.masterData.findPrefecture(
        id,
        this.$i18n.locale
      )

      if (prefecture) {
        this.prefectureSlug = prefecture.slug
      }
    },

    async getPropertyByPreviosCriteria() {
      await this.getPrefectureByID(
        this.previousSearchCriteria._prefecture_id || null
      )

      const searchCriteria = {
        ...this.previousSearchCriteria,
        perPage: PROPERTY_LIMIT,
        page: FRIST_PAGE
      }

      this.properties = await this.$services.property.getUniqueryBuilding(
        searchCriteria
      )

      if (this.properties.length) {
        this.seeMoreQuery = this.previousSearchCriteria
      } else {
        // this.getPropertyByRecommend()
      }
    },

    async getPropertyByRecommend() {
      this.properties = await this.$services.property.getRecommendProperties()

      // this.seeMoreQuery = {
      //   theme: THEME_DESIGNERS.id
      // }
    }
  }
}
</script>
<style lang="scss" scoped>
.item-top {
  .txt-badge {
    font-size: 10px;
    color: #ffffff;
    background-color: #f4218b;
    border-radius: 2px;
    position: absolute;
    top: 0;
    padding: 2px 9px;
    z-index: 1;
  }
}
</style>
