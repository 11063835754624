import { GEOLOCATION_ERRORS } from '@/constants/error'

export default {
  getLocation(options = {}) {
    return new Promise((resolve, reject) => {
      if (!isAvailable()) {
        reject(new Error(GEOLOCATION_ERRORS.NOT_SUPPORT))
      } else {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            })
          },
          (e) => {
            reject(new Error(retrieveErrorMessage(e)))
          },
          options
        )
      }
    })
  },

  parseLatlngStrToObject(latlng) {
    try {
      const [lat, lng] = latlng.split(',')

      return lat && lng ? { lat, lng } : null
    } catch (e) {
      return null
    }
  },

  stringifyLatlng(coordinates) {
    return coordinates.lat && coordinates.lng
      ? `${coordinates.lat},${coordinates.lng}`
      : ''
  }
}

const isAvailable = () => 'geolocation' in window.navigator
const retrieveErrorMessage = function(error) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return GEOLOCATION_ERRORS.PERMISSION_DENIED
    case error.POSITION_UNAVAILABLE:
      return GEOLOCATION_ERRORS.POSITION_UNAVAILABLE
    default:
      return GEOLOCATION_ERRORS.CAN_NOT_GET_LOCATION
  }
}
