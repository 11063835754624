<template>
  <div class="areas_with_the_most_units">
    <div class="sub-title">
      {{ $t('areas_with_the_most_units') }}
    </div>
    <div class="group-tag">
      <template v-for="pref in prefectureMostUnits">
        <nuxt-link
          :key="pref.id"
          class="item-tag mr-1"
          :to="localePath(`/${pref.slug}/list`)"
          >{{ pref.name }}
        </nuxt-link>
      </template>

      <span
        class="item-tag has-cursor-pointer"
        @click="searchByCurrentLocation"
      >
        {{ $t('search_from_your_current_location') }}
      </span>
    </div>
  </div>
</template>

<script>
import { toString } from 'lodash'
import { PREFECTURE_MOST_UNITS } from '@/constants/config'
import geolocationHelper from '@/utils/geolocation'
import prefectureFactory from '@/domain/factories/prefectureFactory'

const RESULT_TYPE_PREFECTURE = 'administrative_area_level_1'
const RESULT_TYPE_CITY = 'locality'
const RESULT_TYPE_POSTAL_CODE = 'postal_code'

export default {
  props: {
    prefectures: {
      type: Array,
      required: true
    }
  },
  computed: {
    prefectureMostUnits() {
      return PREFECTURE_MOST_UNITS.map((id) =>
        this.prefectures.find((pref) => pref.id === id)
      ).filter((pref) => pref !== undefined)
    }
  },
  methods: {
    async searchByCurrentLocation() {
      this.$nuxt.$loading.start()

      try {
        const coordinates = await this.getCoordinates()
        if (!coordinates) {
          return this.$nuxt.$loading.finish()
        }

        const latlng = geolocationHelper.stringifyLatlng(coordinates)

        const reverseGeocodingQuery = {
          latlng,
          result_type: `${RESULT_TYPE_PREFECTURE}|${RESULT_TYPE_CITY}|${RESULT_TYPE_POSTAL_CODE}`
        }
        const reverseGeocodingResponse = await this.$services.googleMap.reverseGeocoding(
          reverseGeocodingQuery
        )

        const reverseGeocodingData = this.retrieveDataFromResponse(
          reverseGeocodingResponse
        )

        const address = await this.$services.address.findAddressByZipCode(
          reverseGeocodingData.postalCode
        )

        const matchedPrefecture = this.retrieveMatchedPrefecture(
          address.master_prefecture_id
        )

        const matchedCity = await matchedPrefecture.findCityByCriteria({
          code: toString(address.municipality_code)
        })

        const searchPath = `/${matchedPrefecture.slug}/list`
        if (!matchedCity) {
          return this.$router.push(
            this.localePath({
              path: searchPath
            })
          )
        }

        this.$router.push(
          this.localePath({
            path: searchPath,
            query: {
              municipality_code: matchedCity.code
            }
          })
        )
      } catch (error) {
        this.$nuxt.$loading.finish()

        alert(
          this.$t(
            'you_are_outside_of_our_service_area_and_cannot_use_this_service'
          )
        )
      }
    },

    retrieveDataFromResponse(reverseGeocodingResponse) {
      const results = reverseGeocodingResponse.results[0].address_components.filter(
        (result) =>
          [
            RESULT_TYPE_CITY,
            RESULT_TYPE_PREFECTURE,
            RESULT_TYPE_POSTAL_CODE
          ].includes(result.types[0])
      )

      const prefecture = results.find((result) =>
        result.types.includes(RESULT_TYPE_PREFECTURE)
      )

      const city = results.find((result) =>
        result.types.includes(RESULT_TYPE_CITY)
      )

      const postalCode = this.retrieveZipcodeFromResponse(results)

      return { prefecture, city, postalCode }
    },

    retrieveZipcodeFromResponse(response) {
      const zipcodeFromResponse = response.find((result) =>
        result.types.includes(RESULT_TYPE_POSTAL_CODE)
      )

      if (!zipcodeFromResponse) return null

      return zipcodeFromResponse.long_name
        ? zipcodeFromResponse.long_name.replace(/-/g, '')
        : null
    },

    retrieveMatchedPrefecture(prefectureID) {
      const matchedPrefecture = this.prefectures.find(
        (prefecture) => prefecture.id === prefectureID
      )

      if (!matchedPrefecture) {
        throw new Error('Sorry, your region is not supported')
      }

      return prefectureFactory(matchedPrefecture)
    },

    async getCoordinates() {
      try {
        const coordinates = await geolocationHelper.getLocation()

        return coordinates
      } catch (error) {
        alert(this.$t(error.message))

        return null
      }
    }
  }
}
</script>
