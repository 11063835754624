<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="topPage">
    <header class="yl-header">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-11 no-padding">
            <h1 class="yl-header__title">{{ $t('live_in_japan') }}</h1>
            <div class="yl-header__sub-title">
              {{
                $t('rental_apartments_and_houses_for_foreigners', {
                  total: this.$options.filters.priceFormat(
                    totalCountPublishedProperties
                  )
                })
              }}
            </div>
            <HomeAreaSelector :prefectures="prefectures"></HomeAreaSelector>
          </div>
        </div>
      </div>
    </header>
    <div class="yl-content">
      <section class="section section-top section-bg">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-11">
              <HomeAreasMostUnits
                :prefectures="prefectures"
              ></HomeAreasMostUnits>
              <div v-if="importantNews" class="card-intro">
                <div class="card-title">{{ importantNews.title }}</div>
                <div class="card-content">
                  {{ importantNews.summary }}
                  <nuxt-link
                    :to="
                      localePath({
                        name: 'news-id',
                        params: {
                          id: importantNews.id
                        }
                      })
                    "
                    >{{ $t('see_more') }}</nuxt-link
                  >
                </div>
              </div>
              <div class="form-row form-search-btn">
                <div
                  :class="[
                    hasPreviousSearchCriteria ? 'col-lg-4' : 'col-lg-12'
                  ]"
                >
                  <button
                    class="btn search-button btn-custom"
                    @click="searchByStation"
                  >
                    <img
                      class="icon"
                      v-lazy-load data-src="~assets/img/ic-station-w.svg"
                      :alt="
                        $t('search_by_train_linestation') +
                          '|' +
                          $t('headMeta.title')
                      "
                    />
                    {{ $t('search_by_train_linestation') }}
                  </button>
                </div>
                <div v-if="hasPreviousSearchCriteria" class="col-lg-8">
                  <SearchRecentBar
                    :previous-search-criteria="previousSearchCriteria"
                  ></SearchRecentBar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section section-intro section-bg">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-5 intro-left">
              <div
                class="intro-title"
                v-html="$t('find_a_place_just_for_you_yolo_home')"
              ></div>
            </div>

            <HomeSpecialTheme class="col-lg-7"></HomeSpecialTheme>
          </div>
        </div>
      </section>
      <HomeSpecialFeature></HomeSpecialFeature>
      <PopularCities />

      <!--recommend properties section -->
      <HomeRecommendProperties
        :previous-search-criteria="previousSearchCriteria"
      ></HomeRecommendProperties>

      <QuickSearchLink :prefectures="prefectures" />

      <section class="section section-notice section-bg">
        <div class="container">
          <div class="row">
            <div class="notice-inner">
              <div class="row">
                <div class="col-6">
                  <div class="section-title">
                    <div class="headTitle">{{ $t('notice') }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="section-button text-right">
                    <nuxt-link :to="localePath('/news')" class="see-more">{{
                      $t('see_more')
                    }}</nuxt-link>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="list-notice">
                    <div v-for="news in newsList" :key="news.id" class="item">
                      <div class="item__left">{{ news.published_date }}</div>
                      <div class="item__right">
                        <nuxt-link
                          :to="localePath(`/news/${news.id}`)"
                          class="text-dark text-decoration-none"
                          >{{ news.title }}</nuxt-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section section-about">
        <div class="container">
          <div class="row justify-content-center">
            <div class="about-inner">
              <div class="headTitle">
                {{ $options.filters.stripTags($t('about_title')) }}
              </div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="text-intro">
                {{ $options.filters.stripTags($t('about_introduction')) }}
              </div>
              <div class="about-row">
                <div class="left">
                  <img
                    class="icon"
                    v-lazy-load data-src="~assets/img/about-ic1@2x.png"
                    :alt="$t('about_target')"
                  />
                </div>
                <div class="right">
                  <h5>
                    {{ $options.filters.stripTags($t('about_target')) }}
                  </h5>
                  <p>
                    {{ $options.filters.stripTags($t('about_target_more')) }}
                  </p>
                </div>
              </div>
              <div class="about-row">
                <div class="left">
                  <img
                    class="icon"
                    v-lazy-load data-src="~assets/img/about-ic2@2x.png"
                    :alt="$t('about_lang_support')"
                  />
                </div>
                <div>
                  <h5>
                    {{ $options.filters.stripTags($t('about_lang_support')) }}
                  </h5>
                  <p>
                    {{
                      $options.filters.stripTags($t('about_lang_support_list'))
                    }}
                  </p>
                </div>
              </div>
              <div class="about-row">
                <div class="left">
                  <img
                    class="icon"
                    v-lazy-load data-src="~assets/img/about-ic3@2x.png"
                    :alt="$t('about_user')"
                  />
                </div>
                <div class="right">
                  <h5>{{ $options.filters.stripTags($t('about_user')) }}</h5>
                  <p>
                    {{ $options.filters.stripTags($t('about_user_note')) }}
                  </p>
                </div>
              </div>
              <nuxt-link class="btn btn-custom" :to="localePath('/flow')">{{
                $t('steps_to_moving_in')
              }}</nuxt-link>
              <img
                class="icon shape-left"
                v-lazy-load data-src="~assets/img/ic-shape1@2x.png"
                alt="border apartments for rent"
              />
              <img
                class="icon shape-right"
                v-lazy-load data-src="~assets/img/ic-shape2@2x.png"
                alt="border apartments for rent"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { SEARCH_TYPE_STATION } from '@/constants/config'
import HomeAreaSelector from '@/components/home/HomeAreaSelector'
import HomeRecommendProperties from '@/components/home/HomeRecommendProperties'
import HomeSpecialTheme from '@/components/home/HomeSpecialTheme'
import HomeAreasMostUnits from '@/components/home/HomeAreasMostUnits'
import HomeSpecialFeature from '@/components/home/HomeSpecialFeature'
import PopularCities from '@/components/common/PopularCities'
import QuickSearchLink from '@/components/common/QuickSearchLink'
import SearchRecentBar from '@/components/common/search/SearchRecentBar'
import HeadMeta from '@/minxins/headMeta'

export default {
  name: 'Home',
  components: {
    HomeAreaSelector,
    HomeRecommendProperties,
    HomeAreasMostUnits,
    HomeSpecialFeature,
    PopularCities,
    QuickSearchLink,
    HomeSpecialTheme,
    SearchRecentBar
  },
  mixins: [HeadMeta],
  async asyncData({ app }) {
    const [
      newsListRes,
      importantNewsRes,
      prefecturesRes,
      totalCountPublishedProperties,
      previousSearchCriteria
    ] = await Promise.all([
      app.$services.news.getTopNews(),
      app.$services.news.getImportantNews(),
      app.$services.masterData.getPrefectures(app.i18n.locale),
      app.$services.property.getTotalCountPublishedProperties(),
      app.$services.list.getSearchCriterias()
    ])

    return {
      prefectures: prefecturesRes,
      newsList: newsListRes.data,
      importantNews: importantNewsRes.data,
      totalCountPublishedProperties,
      previousSearchCriteria
    }
  },
  data() {
    return {
      selectedPrefecture: '',
      prefectures: [],
      newsList: [],
      importantNews: null,
      recommendProperties: [],
      SEARCH_TYPE_STATION,
      headMeta: {
        title: this.$t('headMeta.title'),
        description: this.$t('headMeta.description'),
        type: 'website'
      }
    }
  },
  computed: {
    hasPreviousSearchCriteria() {
      return !!this.previousSearchCriteria
    }
  },
  methods: {
    searchByStation() {
      this.$router.push(
        this.localePath({
          name: 'all',
          query: { type: SEARCH_TYPE_STATION }
        })
      )
    }
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/pages/home.scss';
</style>
